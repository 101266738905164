
.storage {
    padding:0.3125rem 0.5rem !important;

}

.storage-checked,
.storage:hover {
    background-color:#f2faff;
    border-radius: 8px;
}
.storage-dark.storage-checked,
.storage-dark.storage:hover {
    background-color:rgba(0,0,0,.45);
}

.storage .storage-item-edit {
    display:none;
}
.storage-checked .storage-item-edit,.storage:hover .storage-item-edit {
    display:block;
}
.storage-header {
    height:2rem;
    width:100%;
}
.storage-create {
    padding:0.3125rem 0.5rem !important;
    background-color:#f8f9fa;
}

.storage-dark.storage-create {
    background-color:rgba(0,0,0,.45);
}

.storage-upload-list {
    width:23.75rem;
    height:20rem;
    margin-top:1.25rem;
    overflow:hidden;
    overflow-y:scroll;
}
.storage-content {
    overflow-x:hidden;
    overflow-y:scroll;
}
.storage-content-item {
    text-align:center;
    padding-right:1rem;
    padding-left:1rem;
    padding-top:1rem;
    cursor: pointer;
}

.storage-upload-list .ant-list-item-meta-title {
    margin-top:0;
}
